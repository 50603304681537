import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormHariLibur = (formType, formValue) => {
  return { type: "FORM_HARI_LIBUR", formType, formValue };
};

export const setHariLiburs = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetHariLiburs`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_HARI_LIBURS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setHariLibur = (tanggal) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetHariLibur?tanggal=${tanggal}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_HARI_LIBUR", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveHariLibur = (iData,isEdit,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(isEdit){
        Url = `${URLSVC}/Webs/UpdateHariLibur`;
      } else {
        Url = `${URLSVC}/Webs/SaveHariLibur`;
      }

      fd.append('nama', iData.Nama);
      fd.append('keterangan', iData.Keterangan);
      fd.append('tanggal',FUNCDateToString(iData.Tanggal));
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data hari libur`, 'success')
              dispatch(setHariLiburs())
              dispatch({ type: 'MODAL_HARI_LIBUR', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeleteHariLibur = (param) => {
  loadingBar(true);
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus hari libur (${param.nama}) pada tanggal ${param.tanggal}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              http.get(`${URLSVC}/Webs/DeleteHariLibur?tanggal=${param.tanggal}`,fd).then((res) => {
                  let data = res.data;
                  loadingBar(false);
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.nama})`, 'success')
                      dispatch(setHariLiburs())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}