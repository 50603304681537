import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormDokumen = (formType, formValue) => {
  return { type: "FORM_DOKUMEN", formType, formValue };
};

export const setDataDokumens = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiFiles`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_DOKUMENS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataDokumen = (idPegawaiFile) => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(`${URLSVC}/Webs/GetPegawaiFile?IdPegawaiFile=${idPegawaiFile}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.isSuccess) {
          dispatch({ type: "DATA_DOKUMEN", payload: data.data });
        } else {
          if (data.returnMessage != "data tidak ada")
            Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      });
  };
};

export const apiSaveDokumen = (iData, reset) => {
  loadingBar(true);
  return (dispatch) => {
    var Url = "";
    var fd = new FormData();

    if(iData.IdPegawaiFile != "") {
      Url = `${URLSVC}/Webs/UpdatePegawaiFile`;
      fd.append("IdPegawaiFile", iData.IdPegawaiFile);
    } else {
      Url = `${URLSVC}/Webs/SavePegawaiFile`;
    }
    fd.append("JenisPublikasi", iData.JenisPublikasi.value);
    fd.append("Jenis", iData.Jenis.value);
    fd.append("Judul", iData.Judul);
    fd.append(`FileUpload`, iData.FileDokumen.FormFile[0]);
    fd.append(`Tipe`, iData.FileDokumen.Tipe.value);

    http.post(Url, fd).then((res) => {
      let data = res.data;
      loadingBar(false);
      if (data.isSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda berhasil menyimpan data dokumen`,
          "success"
        );
        dispatch(setDataDokumens());
        dispatch({ type: "MODAL_DOKUMEN", payload: false });
        reset();
      } else {
        Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiDeleteDokumen = (param) => {
  loadingBar(true);
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Kamu akan mengahapus dokumen ${param.jenis.name} (${param.judul})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, Saya Yakin!",
      cancelButtonText: "Batalkan",
    }).then((result) => {
      if (result.isConfirmed) {
        var fd = new FormData();
        http
          .get(
            `${URLSVC}/Webs/DeletePegawaiFile?IdPegawaiFile=${param.idPegawaiFile}`,
            fd
          )
          .then((res) => {
            let data = res.data;
            loadingBar(false);
            if (data.isSuccess) {
              Swal.fire(
                "Success",
                `Anda berhasil menghapus data (${param.judul})`,
                "success"
              );
              dispatch(setDataDokumens());
            } else {
              Swal.fire("Fail", `${data.returnMessage}`, "error");
            }
          });
      }
    });
  };
};
