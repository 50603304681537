import { http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormLogin = (formType, formValue) => {
  return { type: "FORM_LOGIN", formType, formValue };
};

export const apiLogin = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("UsernameOrEmail", iData.UsernameOrEmail);
    fd.append("Password", iData.Password);

    http
      .post(
        `${URLSVC}/Webs/Login?UsernameOrEmail=${iData.UsernameOrEmail}&Password=${iData.Password}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.isSuccess) {
          localStorage.setItem("TOKEN", data.data);
          Swal.fire("Berhasil", `Selamat datang`, "success").then(
            () => (window.location = "/")
          );
        } else {
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      });
  };
};

export const apiResetPassword = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Email", iData.Email);

    http.post(`${URLSVC}/Webs/ResetPassword`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Reset password berhasil dikirim, silahkan cek email anda!`,
          "success"
        );
        dispatch({ type: "MODAL_LUPA_PASSWORD", payload: false });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
