const initialState = {
    dataPegawaiCutis: [],
    dataPegawaiCuti: null,
    modalPegawaiCuti: false,
    formPegawaiCuti: {},
    fileImageUserPreview: "",
  };
  
  const pegawaiCutiReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PEGAWAI_CUTIS":
        return { ...state, dataPegawaiCutis: action.payload };
      case 'MODAL_PEGAWAI_CUTI':
        return { ...state, modalPegawaiCuti: action.payload }
      case "DATA_PEGAWAI_CUTI":
        return { ...state, dataPegawaiCuti: action.payload };
      case "FORM_PEGAWAI_CUTI":
        return {
          ...state,
          formPegawaiCuti: {
            ...state.formPegawaiCuti,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_PEGAWAI_CUTI":
        return { ...state, formPegawaiCuti: initialState.formPegawaiCuti };
      default:
        return state;
    }
  };
  
  export default pegawaiCutiReducer;
  