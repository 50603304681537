import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormUnit = (formType, formValue) => {
  return { type: "FORM_UNIT", formType, formValue };
};

export const setDataUnits = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetUnits`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_UNITS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataUnit = (idUnit) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetUnit?IdUnit=${idUnit}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_UNIT", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveUnit = (iData,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(iData.IdUnit != ""){
        Url = `${URLSVC}/Webs/UpdateUnit`;
        fd.append('IdUnit', iData.IdUnit);
      } else {
        Url = `${URLSVC}/Webs/SaveUnit`;
      }
      fd.append('nama', iData.Nama);
      fd.append('namaSingkat', iData.NamaSingkat);
      fd.append('kuotaCuti', iData.KuotaCuti);
      fd.append('alamat', iData.Alamat);
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data unit`, 'success')
              dispatch(setDataUnits())
              dispatch({ type: 'MODAL_UNIT', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeleteUnit = (param) => {
  loadingBar(true);
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus unit ${param.nama} (${param.namaSingkat})!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              http.get(`${URLSVC}/Webs/DeleteUnit?idUnit=${param.idUnit}`,fd).then((res) => {
                  let data = res.data;
                  loadingBar(false);
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.judul})`, 'success')
                      dispatch(setDataUnits())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}