import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

function ReactDatePicker({...props}) {
    return (
        <DatePicker
            className="form-control"
            {...props}
        />
    )
}

export default ReactDatePicker;
