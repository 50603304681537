import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormKegiatan = (formType, formValue) => {
  return { type: "FORM_KEGIATAN", formType, formValue };
};

export const setDataKegiatans = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKegiatans`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_KEGIATANS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataKegiatan = (idKegiatan) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKegiatan?IdKegiatan=${idKegiatan}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_KEGIATAN", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveKegiatan = (iData,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(iData.IdKegiatan != null){
        Url = `${URLSVC}/Webs/EditKegiatan`;
        fd.append('IdKegiatan', iData.IdKegiatan);
      } else {
        Url = `${URLSVC}/Webs/SaveKegiatan`;
      }
      
      fd.append('JenisPublikasi', iData.JenisPublikasi.value);
      fd.append('Judul', iData.Judul);
      fd.append('Tanggal', FUNCDateToString(iData.Tanggal));
      fd.append('Jam', iData.Jam);
      fd.append('Keterangan', iData.Keterangan);

      iData.DataFileKegiatans.map((item,index) => {
        if(item.FormFile[0] != undefined){
          fd.append(`FileToUploads[${index}].FormFile`, item.FormFile[0]);
          fd.append(`FileToUploads[${index}].Nama`, item.Nama);
          fd.append(`FileToUploads[${index}].Tipe`, item.Tipe);
          fd.append(`FileToUploads[${index}].Id`, item.Id);
        }
      })
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data pengumuman`, 'success')
              dispatch(setDataKegiatans())
              dispatch({ type: 'MODAL_KEGIATAN', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeleteKegiatan = (param) => {
  loadingBar(true);
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus pengumuman ${param.jenis.name} (${param.judul}) tanggal ${param.tanggal}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdKegiatan', param.idKegiatan);
              http.post(`${URLSVC}/Webs/DeleteKegiatan`,fd).then((res) => {
                  let data = res.data;
                  loadingBar(false);
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.judul})`, 'success')
                      dispatch(setDataKegiatans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}