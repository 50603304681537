const initialState = {
    dataPegawaiJadwals: [],
    dataPegawaiJadwal: null,
    modalPegawaiJadwal: false,
    formPegawaiJadwal: {},
    fileImageUserPreview: "",
  };
  
  const pegawaiJadwalReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PEGAWAI_JADWALS":
        return { ...state, dataPegawaiJadwals: action.payload };
      case 'MODAL_PEGAWAI_JADWAL':
        return { ...state, modalPegawaiJadwal: action.payload }
      case "DATA_PEGAWAI_JADWAL":
        return { ...state, dataPegawaiJadwal: action.payload };
      case "FORM_PEGAWAI_JADWAL":
        return {
          ...state,
          formPegawaiJadwal: {
            ...state.formPegawaiJadwal,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_PEGAWAI_JADWAL":
        return { ...state, formPegawaiJadwal: initialState.formPegawaiJadwal };
      default:
        return state;
    }
  };
  
  export default pegawaiJadwalReducer;
  