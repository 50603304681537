import { combineReducers } from "redux";

import sidebarReducer from "./sidebarReducer";
import comboReducer from "./comboReducer";
import globalReducer from "./globalReducer";
import loginReducer from "./loginReducer";
import pengumumanReducer from "./pengumumanReducer";
import kegiatanReducer from "./kegiatanReducer";
import pegawaiReducer from "./kepegawaian/pegawaiReducer";
import gajiReducer from "./kepegawaian/gajiReducer";
import dokumenReducer from "./kepegawaian/dokumenReducer";
import pengajuanReducer from "./pengajuanReducer";
import hariLiburReducer from "./hariLiburReducer";
import monitoringAbsenReducer from "./monitoringAbsenReducer";
import userReducer from "./userReducer";
import unitReducer from "./unitReducer";
import unitJadwalReducer from "./unitJadwalReducer";
import pegawaiJadwalReducer from "./pegawaiJadwalReducer";
import pegawaiCutiReducer from "./kepegawaian/pegawaiCutiReducer";
import jabatanReducer from "./kepegawaian/jabatanReducer";
import lokasiPresensiReducer from "./kepegawaian/lokasiPresensiReducer";
import pegawaiAktivitasReducer from "./kepegawaian/pegawaiAktivitasReducer";

import ubahProfileReducer from "./pengaturan/ubahProfileReducer";
import ubahPasswordReducer from "./pengaturan/ubahPasswordReducer";

import laporanAbsensiHarianReducer from "./laporan/laporanAbsensiHarianReducer";
import laporanAbsensiRekapReducer from "./laporan/laporanAbsensiRekapReducer";
import homeReducer from "./homeReducer";

const reducer = combineReducers({
  sidebarReducer,
  comboReducer,
  globalReducer,
  loginReducer,
  pengumumanReducer,
  kegiatanReducer,
  pegawaiReducer,
  gajiReducer,
  dokumenReducer,
  pengajuanReducer,
  hariLiburReducer,
  userReducer,
  unitReducer,
  unitJadwalReducer,
  pegawaiJadwalReducer,
  pegawaiCutiReducer,
  jabatanReducer,
  lokasiPresensiReducer,
  pegawaiAktivitasReducer,
  ubahProfileReducer,
  ubahPasswordReducer,
  laporanAbsensiHarianReducer,
  laporanAbsensiRekapReducer,
  homeReducer
});

export default reducer;
