import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormUnitJadwal = (formType, formValue) => {
  return { type: "FORM_UNIT_JADWAL", formType, formValue };
};

export const setUnitJadwals = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetUnitJadwals`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_UNIT_JADWALS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setUnitJadwal = (idUnit) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetUnitJadwal?idUnit=${idUnit}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_UNIT_JADWAL", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveUnitJadwal = (iData,isEdit,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(isEdit){
        Url = `${URLSVC}/Webs/UpdateUnitJadwal`;
      } else {
        Url = `${URLSVC}/Webs/SaveUnitJadwal`;
      }

      fd.append('idUnit', iData.IdUnit?.value);
      fd.append('jadwalHadir', iData.JadwalHadir);
      fd.append('jadwalKeluar', iData.JadwalKeluar);
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data jadwal unit`, 'success')
              dispatch(setUnitJadwals())
              dispatch({ type: 'MODAL_UNIT_JADWAL', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeleteUnitJadwal = (param) => {
  loadingBar(true);
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus jadwal unit (${param.nama})!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              http.get(`${URLSVC}/Webs/DeleteUnitJadwal?idUnit=${param.idUnit}`,fd).then((res) => {
                  let data = res.data;
                  loadingBar(false);
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.nama})`, 'success')
                      dispatch(setUnitJadwals())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}