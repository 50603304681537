import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormJabatan = (formType, formValue) => {
  return { type: "FORM_JABATAN", formType, formValue };
};

export const setJabatans = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetJabatans`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_JABATANS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setJabatan = (idJabatan) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetJabatan?idJabatan=${idJabatan}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_JABATAN", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveJabatan = (iData,isEdit,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(isEdit){
        Url = `${URLSVC}/Webs/UpdateJabatan`;
        fd.append('idJabatan', iData.IdJabatan);
      } else {
        Url = `${URLSVC}/Webs/SaveJabatan`;
      }

      fd.append('nama', iData.Nama);
      fd.append('namaSingkat', iData.NamaSingkat);
      fd.append('level', iData.Level);
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
                dispatch(setJabatans());
                Swal.fire('Berhasil', `Anda berhasil menyimpan data jabatan`, 'success');
                dispatch({ type: 'MODAL_JABATAN', payload: false })
                reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}
