import { FUNCDateToString, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormPegawai = (formType, formValue) => {
  return { type: "FORM_PEGAWAI", formType, formValue };
};

export const setDataPegawais = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawais`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAIS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataPegawai = (idPegawai) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawai?IdPegawai=${idPegawai}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI", payload: data.data });
      } else {
        //if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSavePegawai = (iData,reset) => {
  return (dispatch) => {
      console.log("iData PEGAWAI",iData);
      var Url = "";
      var fd = new FormData();

      Url = `${URLSVC}/Webs/SavePegawai`;
      fd.append('IdUnit', iData.IdUnit.value);
      fd.append('IdRole', iData.IdRole.value);
      fd.append('Nip', iData.Nip);
      fd.append('IdJabatan', iData.IdJabatan.value);
      fd.append('JenisPegawai', iData.JenisPegawai.value);
      fd.append('TanggalMasuk', FUNCDateToString(iData.TanggalMasuk));
      fd.append('IdGolonganPegawai', iData.IdGolonganPegawai.value);
      fd.append('Email', iData.Email);
      fd.append('FirstName', iData.FirstName);
      fd.append('MiddleName', iData.MiddleName);
      fd.append('LastName', iData.LastName);
      fd.append('Address', iData.Address);
      fd.append('PhoneNumber', iData.PhoneNumber);
      fd.append('MobileNumber', iData.MobileNumber);
      fd.append('TempatLahir', iData.TempatLahir);
      fd.append('TanggalLahir', FUNCDateToString(iData.TanggalLahir));
      fd.append('JenisKelamin', iData.JenisKelamin.value);
      fd.append('Nik', iData.Nik);
      fd.append('IdStatusPerkawinan', iData.IdStatusPerkawinan.value);
      fd.append('Agama', iData.Agama.value);
      fd.append('IdJenjangPendidikan', iData.IdJenjangPendidikan.value);
      fd.append('NamaInstitusiPendidikan', iData.NamaInstitusiPendidikan);
      fd.append('FotoProfil', iData.FotoProfil[0]);

      http.post(Url,fd).then((res) => {
          let data = res.data;
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data pegawai`, 'success')
              dispatch(setDataPegawais())
              dispatch({ type: 'MODAL_PEGAWAI', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiSaveEditPegawai = (iData,reset) => {
  return (dispatch) => {
      console.log("iData PEGAWAI",iData);
      var Url = "";
      var fd = new FormData();

      Url = `${URLSVC}/Webs/EditKepegawaian`;
      fd.append('IdPegawai', iData.IdPegawai);
      fd.append('IdRole', iData.IdRole.value);
      fd.append('JenisPegawai', iData.JenisPegawai.value);
      fd.append('IdUnit', iData.IdUnit.value);
      fd.append('IdJabatan', iData.IdJabatan.value);
      fd.append('IdGolonganPegawai', iData.IdGolonganPegawai.value);
      fd.append('TanggalMasuk', FUNCDateToString(iData.TanggalMasuk));
      fd.append('IdJenjangPendidikan', iData.IdJenjangPendidikan.value);
      fd.append('NamaInstitusiPendidikan', iData.NamaInstitusiPendidikan);
      fd.append('Gelar', iData.Gelar);
      fd.append('JurusanPendidikan', iData.JurusanPendidikan);
      fd.append('PengalamanKerja', iData.PengalamanKerja);
      fd.append('Kompetensi', iData.Kompetensi);

      http.post(Url,fd).then((res) => {
          let data = res.data;
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data pegawai`, 'success')
              dispatch(setDataPegawais())
              dispatch({ type: 'MODAL_EDIT_PEGAWAI', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}