import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormPegawaiJadwal = (formType, formValue) => {
  return { type: "FORM_PEGAWAI_JADWAL", formType, formValue };
};

export const setPegawaiJadwals = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiJadwals`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_JADWALS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setPegawaiJadwal = (idPegawaiKehadiranJadwal) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiJadwal?idPegawaiKehadiranJadwal=${idPegawaiKehadiranJadwal}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_JADWAL", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSavePegawaiJadwal = (iData,isEdit,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(isEdit){
        Url = `${URLSVC}/Webs/UpdatePegawaiJadwal`;
        fd.append('idPegawaiKehadiranJadwal', iData.IdPegawaiKehadiranJadwal);
      } else {
        if(iData.SaveBy == "1"){
          Url = `${URLSVC}/Webs/SavePegawaiJadwal`;
          fd.append('idPegawai', iData.IdPegawai?.value);
        } else if(iData.SaveBy == "2"){
          Url = `${URLSVC}/Webs/SavePegawaiJadwalByJabatan`;
          fd.append('idJabatan', iData.IdJabatan?.value);
        }
        fd.append('hariKe', iData.HariKe?.value);
      }
      fd.append('jadwalHadir', iData.JadwalHadir);
      fd.append('jadwalKeluar', iData.JadwalKeluar);
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data jadwal unit`, 'success')
              dispatch(setPegawaiJadwals())
              dispatch({ type: 'MODAL_PEGAWAI_JADWAL', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeletePegawaiJadwal = (param) => {
  loadingBar(true);
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus jadwal unit (${param.nama})!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              http.get(`${URLSVC}/Webs/DeletePegawaiJadwal?idPegawaiKehadiranJadwal=${param.idPegawaiKehadiranJadwal}`,fd).then((res) => {
                  let data = res.data;
                  loadingBar(false);
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.nama})`, 'success')
                      dispatch(setPegawaiJadwals())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}