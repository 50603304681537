const initialState = {
    dataLaporanAbsensiHarians: [],
    dataLaporanAbsensiHarian: null,
    modalLaporanAbsensiHarian: false,
    formLaporanAbsensiHarian: {},
    fileImageUserPreview: "",
    modalLaporanJadwal: false,
  };
  
  const laporanAbsensiHarianReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_LAPORAN_ABSENSI_HARIANS":
        return { ...state, dataLaporanAbsensiHarians: action.payload };
      case 'MODAL_LAPORAN_ABSENSI_HARIAN':
        return { ...state, modalLaporanAbsensiHarian: action.payload }
      case "DATA_LAPORAN_ABSENSI_HARIAN":
        return { ...state, dataLaporanAbsensiHarian: action.payload };
      case "FORM_LAPORAN_ABSENSI_HARIAN":
        return {
          ...state,
          formLaporanAbsensiHarian: {
            ...state.formLaporanAbsensiHarian,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_LAPORAN_ABSENSI_HARIAN":
        return { ...state, formLaporanAbsensiHarian: initialState.formLaporanAbsensiHarian };
      case "MODAL_LAPORAN_JADWAL":
        return { ...state, modalLaporanJadwal: action.payload };
      default:
        return state;
    }
  };
  
  export default laporanAbsensiHarianReducer;
  