import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormLaporanAbsensiRekap = (formType, formValue) => {
  return { type: "FORM_LAPORAN_ABSENSI_REKAP", formType, formValue };
};

export const setDataLaporanAbsensiRekaps = (TanggalMulai="",TanggalAkhir="") => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetRankingAbsensiRekaps?TanggalMulai=${TanggalMulai}&TanggalAkhir=${TanggalAkhir}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_LAPORAN_ABSENSI_REKAPS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const GenerateExcelKehadiranBulanan = (Bulan,Tahun) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GenerateExcelKehadiranBulanan?Bulan=${Bulan}&Tahun=${Tahun}`,{responseType:"blob"}).then((res) => {
      loadingBar(false);
      let data = res.data;
      window.open(window.URL.createObjectURL(data))
      // if (data.isSuccess) {
      //   dispatch({ type: "DATA_LAPORAN_ABSENSI_REKAP", payload: data.data });
      // } else {
      //   if (data.returnMessage != "data tidak ada")
      //     Swal.fire("Gagal", `${data.returnMessage}`, "error");
      // }
    });
  };
};