import { http } from 'src/config';
import { URLSVC, URL } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setFormUbahPassword = (formType, formValue) => {
    return {type: 'FORM_UBAH_PASSWORD', formType, formValue}
}

export const apiChangePassword = (iData) => {
    loadingBar(true);
    return (dispatch) => {
        var fd = new FormData();

        fd.append('OldPassword', iData.OldPassword);
        fd.append('NewPassword1', iData.NewPassword1);
        fd.append('NewPassword2', iData.NewPassword2);

        http.post(`${URLSVC}/Webs/ChangePassword`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui password`, 'success').then(function() {
                    window.location.replace('/')
                });
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error')
            }
        })
    }
}