const initialState = {
    dataUnitJadwals: [],
    dataUnitJadwal: null,
    modalUnitJadwal: false,
    formUnitJadwal: {},
    fileImageUserPreview: "",
  };
  
  const unitJadwalReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_UNIT_JADWALS":
        return { ...state, dataUnitJadwals: action.payload };
      case 'MODAL_UNIT_JADWAL':
        return { ...state, modalUnitJadwal: action.payload }
      case "DATA_UNIT_JADWAL":
        return { ...state, dataUnitJadwal: action.payload };
      case "FORM_UNIT_JADWAL":
        return {
          ...state,
          formUnitJadwal: {
            ...state.formUnitJadwal,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_UNIT_JADWAL":
        return { ...state, formUnitJadwal: initialState.formUnitJadwal };
      default:
        return state;
    }
  };
  
  export default unitJadwalReducer;
  