import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyATQvfPpiTxvNjFG9N1wnEuOpKhGVQPSWo",
    authDomain: "salimseal.firebaseapp.com",
    databaseURL: "https://salimseal.firebaseio.com",
    projectId: "salimseal",
    storageBucket: "salimseal.appspot.com",
    messagingSenderId: "696825787450",
    appId: "1:696825787450:web:c45173b665b85b8f7f3103"
  });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    console.log('token do usuário:', token);
    return token;
  } catch (error) {
    console.error(error);
  }
}
