const initialState = {
    dataLokasiPresensis: [],
    dataLokasiPresensi: null,
    modalLokasiPresensi: false,
    formLokasiPresensi: {},
    fileImageUserPreview: "",
  };
  
  const lokasiPresensiReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_LOKASI_PRESENSIS":
        return { ...state, dataLokasiPresensis: action.payload };
      case 'MODAL_LOKASI_PRESENSI':
        return { ...state, modalLokasiPresensi: action.payload }
      case "DATA_LOKASI_PRESENSI":
        return { ...state, dataLokasiPresensi: action.payload };
      case "FORM_LOKASI_PRESENSI":
        return {
          ...state,
          formLokasiPresensi: {
            ...state.formLokasiPresensi,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_LOKASI_PRESENSI":
        return { ...state, formLokasiPresensi: initialState.formLokasiPresensi };
      default:
        return state;
    }
  };
  
  export default lokasiPresensiReducer;
  