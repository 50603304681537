import { http, URLSVC } from "src/config";
import Swal from "sweetalert2";

export const setImgPreview = (type, payload) => {
  return { type, payload };
};

export const loadingBar = (bool = true) => {
  if (bool) {
    Swal.fire({
      title: "Sedang Memuat",
      html: "silahkan tunggu sebentar...",
      showConfirmButton: false,
      onBeforeOpen() {
        Swal.showLoading();
      },
      onAfterClose() {
        Swal.hideLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  } else {
    Swal.close();
  }
};

// export const setDataUserInfo = () => {
//   return (dispatch) => {
//     http.get(`${URLSVC}/Webs/GetInfoUser`).then((res) => {
//       loadingBar(false);
//       let data = res.data;
//       if (data.isSuccess) {
//         data = data.data;
//         localStorage.setItem("DATA_USER", JSON.stringify(data));
//         dispatch({ type: "GB_DATA_USER", payload: data });
//       } else {
//         Swal.fire("Gagal", `${data.returnMessage}`, "error");
//       }
//     });
//   };
// };
