const initialState = {
    dataKegiatans: [],
    dataKegiatan: null,
    modalKegiatan: false,
    formKegiatan: {},
    fileImageUserPreview: "",
    modalLaporanJadwal: false,
  };
  
  const kegiatanReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_KEGIATANS":
        return { ...state, dataKegiatans: action.payload };
      case 'MODAL_KEGIATAN':
        return { ...state, modalKegiatan: action.payload }
      case "DATA_KEGIATAN":
        return { ...state, dataKegiatan: action.payload };
      case "FORM_KEGIATAN":
        return {
          ...state,
          formKegiatan: {
            ...state.formKegiatan,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_KEGIATAN":
        return { ...state, formKegiatan: initialState.formKegiatan };
      case "MODAL_LAPORAN_JADWAL":
        return { ...state, modalLaporanJadwal: action.payload };
      default:
        return state;
    }
  };
  
  export default kegiatanReducer;
  