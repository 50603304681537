const initialState = {
    dataJabatans: [],
    dataJabatan: null,
    modalJabatan: false,
    formJabatan: {},
    fileImageUserPreview: "",
  };
  
  const jabatanReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_JABATANS":
        return { ...state, dataJabatans: action.payload };
      case 'MODAL_JABATAN':
        return { ...state, modalJabatan: action.payload }
      case "DATA_JABATAN":
        return { ...state, dataJabatan: action.payload };
      case "FORM_JABATAN":
        return {
          ...state,
          formJabatan: {
            ...state.formJabatan,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_JABATAN":
        return { ...state, formJabatan: initialState.formJabatan };
      default:
        return state;
    }
  };
  
  export default jabatanReducer;
  