const initialState = {
    dataPengumumans: [],
    dataPengumuman: null,
    modalPengumuman: false,
    formPengumuman: {},
    fileImageUserPreview: "",
    modalLaporanJadwal: false,
  };
  
  const pengumumanReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PENGUMUMANS":
        return { ...state, dataPengumumans: action.payload };
      case 'MODAL_PENGUMUMAN':
        return { ...state, modalPengumuman: action.payload }
      case "DATA_PENGUMUMAN":
        return { ...state, dataPengumuman: action.payload };
      case "FORM_PENGUMUMAN":
        return {
          ...state,
          formPengumuman: {
            ...state.formPengumuman,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_PENGUMUMAN":
        return { ...state, formPengumuman: initialState.formPengumuman };
      case "MODAL_LAPORAN_JADWAL":
        return { ...state, modalLaporanJadwal: action.payload };
      default:
        return state;
    }
  };
  
  export default pengumumanReducer;
  