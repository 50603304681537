import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormPengumuman = (formType, formValue) => {
  return { type: "FORM_PENGUMUMAN", formType, formValue };
};

export const setDataPengumumans = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPengumumans`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PENGUMUMANS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataPengumuman = (idPengumuman) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPengumuman?IdPengumuman=${idPengumuman}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PENGUMUMAN", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSavePengumuman = (iData,reset) => {
  return (dispatch) => {
      console.log("iData PENGUMUMAN",iData);
      var Url = "";
      var fd = new FormData();

      if(iData.IdPengumuman != null){
        Url = `${URLSVC}/Webs/EditPengumuman`;
        fd.append('IdPengumuman', iData.IdPengumuman);
      } else {
        Url = `${URLSVC}/Webs/SavePengumuman`;
      }
      
      fd.append('Jenis', iData.Jenis.value);
      fd.append('JenisPublikasi', iData.JenisPublikasi.value);
      fd.append('Judul', iData.Judul);
      fd.append('Keterangan', iData.Keterangan);

      iData.DataFilePengumumans.map((item,index) => {
        if(item.FormFile[0] != undefined){
          fd.append(`FileToUploads[${index}].FormFile`, item.FormFile[0]);
          fd.append(`FileToUploads[${index}].Nama`, item.Nama);
          fd.append(`FileToUploads[${index}].Tipe`, item.Tipe);
          fd.append(`FileToUploads[${index}].Id`, item.Id);
        }
      })
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data pengumuman`, 'success')
              dispatch(setDataPengumumans())
              dispatch({ type: 'MODAL_PENGUMUMAN', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeletePengumuman = (param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus pengumuman ${param.jenis.name} (${param.judul}) tanggal ${param.tanggal}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPengumuman', param.idPengumuman);
              http.post(`${URLSVC}/Webs/DeletePengumuman`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.judul})`, 'success')
                      dispatch(setDataPengumumans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}

export const apiRejectPengumuman = (param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan me-reject pengumuman ${param.jenis.name} (${param.judul}) tanggal ${param.tanggal}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPengumuman', param.idPengumuman);
              http.post(`${URLSVC}/Webs/RejectPengumuman`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                      Swal.fire('Success', `You have successfully rejected data (${param.judul})`, 'success')
                      dispatch(setDataPengumumans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}

export const apiPublishPengumuman = (param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mem-publish pengumuman ${param.jenis.name} (${param.judul}) tanggal ${param.tanggal}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPengumuman', param.idPengumuman);
              http.post(`${URLSVC}/Webs/PublishPengumuman`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                      Swal.fire('Success', `You have successfully published data (${param.judul})`, 'success')
                      dispatch(setDataPengumumans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}