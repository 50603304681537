const initialState = {
    dataPegawais: [],
    dataPegawaiJadwals: [],
    dataPegawai: null,
    modalPegawai: false,
    modalEditPegawai: false,
    formPegawai: {},
    fileImageUserPreview: "",
    modalLaporanJadwal: false,
  };
  
  const pegawaiReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PEGAWAIS":
        return { ...state, dataPegawais: action.payload };
      case 'MODAL_PEGAWAI':
        return { ...state, modalPegawai: action.payload }
      case 'MODAL_EDIT_PEGAWAI':
        return { ...state, modalEditPegawai: action.payload }
      case "DATA_PEGAWAI_JADWALS":
        return { ...state, dataPegawaiJadwals: action.payload };
      case "DATA_PEGAWAI":
        return { ...state, dataPegawai: action.payload };
      case "FORM_PEGAWAI":
        return {
          ...state,
          formPegawai: {
            ...state.formPegawai,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_PEGAWAI":
        return { ...state, formPegawai: initialState.formPegawai };
      case "MODAL_LAPORAN_JADWAL":
        return { ...state, modalLaporanJadwal: action.payload };
      default:
        return state;
    }
  };
  
  export default pegawaiReducer;
  