const initialState = {
    dataUnits: [],
    dataUnit: null,
    modalUnit: false,
    formUnit: {},
    fileImageUserPreview: "",
    modalLaporanJadwal: false,
  };
  
  const unitReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_UNITS":
        return { ...state, dataUnits: action.payload };
      case 'MODAL_UNIT':
        return { ...state, modalUnit: action.payload }
      case "DATA_UNIT":
        return { ...state, dataUnit: action.payload };
      case "FORM_UNIT":
        return {
          ...state,
          formUnit: {
            ...state.formUnit,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_UNIT":
        return { ...state, formUnit: initialState.formUnit };
      case "MODAL_LAPORAN_JADWAL":
        return { ...state, modalLaporanJadwal: action.payload };
      default:
        return state;
    }
  };
  
  export default unitReducer;
  