const initialState = {
    dataHariLiburs: [],
    dataHariLibur: null,
    modalHariLibur: false,
    formHariLibur: {},
    fileImageUserPreview: "",
  };
  
  const hariLiburReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_HARI_LIBURS":
        return { ...state, dataHariLiburs: action.payload };
      case 'MODAL_HARI_LIBUR':
        return { ...state, modalHariLibur: action.payload }
      case "DATA_HARI_LIBUR":
        return { ...state, dataHariLibur: action.payload };
      case "FORM_HARI_LIBUR":
        return {
          ...state,
          formHariLibur: {
            ...state.formHariLibur,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_HARI_LIBUR":
        return { ...state, formHariLibur: initialState.formHariLibur };
      default:
        return state;
    }
  };
  
  export default hariLiburReducer;
  