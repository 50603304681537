const initialState = {
    dataPegawaiAktivitasKehadirans: [],
    modalPegawaiAktivitasKehadirans: false,
    dataPegawaiAktivitass: [],
    dataPegawaiAktivitas: null,
    modalPegawaiAktivitas: false,
    formPegawaiAktivitas: {},
    fileImageUserPreview: "",
  };
  
  const pegawaiAktivitasReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PEGAWAI_AKTIVITAS_KEHADIRANS":
        return { ...state, dataPegawaiAktivitasKehadirans: action.payload };
      case 'MODAL_PEGAWAI_AKTIVITAS_KEHADIRANS':
        return { ...state, modalPegawaiAktivitasKehadirans: action.payload }
      case "DATA_PEGAWAI_AKTIVITASS":
        return { ...state, dataPegawaiAktivitass: action.payload };
      case 'MODAL_PEGAWAI_AKTIVITAS':
        return { ...state, modalPegawaiAktivitas: action.payload }
      case "DATA_PEGAWAI_AKTIVITAS":
        return { ...state, dataPegawaiAktivitas: action.payload };
      case "FORM_PEGAWAI_AKTIVITAS":
        return {
          ...state,
          formPegawaiAktivitas: {
            ...state.formPegawaiAktivitas,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_PEGAWAI_AKTIVITAS":
        return { ...state, formPegawaiAktivitas: initialState.formPegawaiAktivitas };
      default:
        return state;
    }
  };
  
  export default pegawaiAktivitasReducer;
  