import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "./globalAction";

export const setFormPengajuan = (formType, formValue) => {
  return { type: "FORM_PENGAJUAN", formType, formValue };
};

export const setDataPengajuans = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiPengajuans`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PENGAJUANS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataPengajuan = (idPengajuan) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiPengajuan?IdPegawaiPengajuan=${idPengajuan}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PENGAJUAN", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSavePengajuan = (iData,reset) => {
  return (dispatch) => {
      console.log("iData PENGAJUAN",iData);
      var Url = "";
      var fd = new FormData();

      if(iData.IdPegawaiPengajuan != null){
        Url = `${URLSVC}/Webs/UpdatePengajuan`;
        fd.append('IdPegawaiPengajuan', iData.IdPegawaiPengajuan);
      } else {
        Url = `${URLSVC}/Webs/SavePengajuan`;
      }
      console.log("iData.IdPegawaiPengajuan",iData.IdPegawaiPengajuan);
      fd.append('IdPegawai', iData.IdPegawai?.value);
      fd.append('Jenis', iData.Jenis.value);
      fd.append('IdJenisCuti', iData.IdJenisCuti.value);
      fd.append('TanggalMulai', FUNCDateToString(iData.TanggalMulai));
      fd.append('TanggalAkhir', FUNCDateToString(iData.TanggalAkhir));
      fd.append('Durasi', iData.Durasi);
      fd.append('Alasan', iData.Alasan);
      fd.append('FormFile', iData.FormFile[0]);

      http.post(Url,fd).then((res) => {
          let data = res.data;
          if (data.isSuccess) {
              dispatch(setDataPengajuans())
              dispatch({ type: 'MODAL_PENGAJUAN', payload: false })
              reset();
              Swal.fire('Berhasil', `Anda berhasil menyimpan data pengajuan`, 'success');
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeletePengajuan = (param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus pengajuan atas nama ${param.namaPegawai} untuk ${param.jenis.name} ${param?.jenisCuti?.nama} tanggal ${FUNCIndoDate(param.tanggalMulai)} sampai dengan ${FUNCIndoDate(param.tanggalAkhir)}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPegawaiPengajuan', param.idPegawaiPengajuan);
              http.post(`${URLSVC}/Webs/DeletePengajuan`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menghapus data (${param.judul})`, 'success')
                      dispatch(setDataPengajuans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}

export const apiApprovePengajuan = (param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan menyetujui pengajuan atas nama ${param.namaPegawai} untuk ${param.jenis.name} ${param?.jenisCuti?.nama} tanggal ${FUNCIndoDate(param.tanggalMulai)} sampai dengan ${FUNCIndoDate(param.tanggalAkhir)}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPegawaiPengajuan', param.idPegawaiPengajuan);
              http.post(`${URLSVC}/Webs/SetApprovePengajuan`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil menyetujui data (${param.judul})`, 'success')
                      dispatch(setDataPengajuans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}

export const apiRejectPengajuan = (param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan me-reject pengajuan atas nama ${param.namaPegawai} untuk ${param.jenis.name} ${param?.jenisCuti?.nama} tanggal ${FUNCIndoDate(param.tanggalMulai)} sampai dengan ${FUNCIndoDate(param.tanggalAkhir)}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPegawaiPengajuan', param.idPegawaiPengajuan);
              http.post(`${URLSVC}/Webs/SetRejectPengajuan`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                      Swal.fire('Success', `Anda berhasil me-reject data (${param.judul})`, 'success')
                      dispatch(setDataPengajuans())
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}