const initialState = {
  dataUsers: [],
  dataUser: null,
  modalUser: false,
  formAddUser: {
    IdUser: "",
    Username: "",
    Password: "",
    Email: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address: "",
    PhoneNumber: "",
    MobileNumber: "",
    Role: "",
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_USERS":
      return { ...state, dataUsers: action.payload };
    case "DATA_USER":
      return { ...state, dataUser: action.payload };
    case "FORM_USER":
      return {
        ...state,
        formAddUser: {
          ...state.formAddUser,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_USER":
      return { ...state, formAddUser: initialState.formAddUser };
    case "MODAL_USER":
      return { ...state, modalUser: action.payload };
    default:
      return state;
  }
};

export default userReducer;
