const initialState = {
    dataDokumens: [],
    dataDokumen: null,
    modalDokumen: false,
    formDokumen: {},
    fileImageUserPreview: "",
  };
  
  const kegiatanReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_DOKUMENS":
        return { ...state, dataDokumens: action.payload };
      case 'MODAL_DOKUMEN':
        return { ...state, modalDokumen: action.payload }
      case "DATA_DOKUMEN":
        return { ...state, dataDokumen: action.payload };
      case "FORM_DOKUMEN":
        return {
          ...state,
          formDokumen: {
            ...state.formDokumen,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_DOKUMEN":
        return { ...state, formDokumen: initialState.formDokumen };
      default:
        return state;
    }
  };
  
  export default kegiatanReducer;
  