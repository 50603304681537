const initialState = {
  dataComboHari: [],
  dataComboUnit: [],
  dataComboRole: [],
  dataComboAgama: [],
  dataComboJabatan: [],
  dataComboJenisPegawai: [],
  dataComboJenisPengajuan: [],
  dataComboCuti: [],
  dataComboJenisPengumuman: [],
  dataComboJenisPublikasiKegiatan: [],
  dataComboJenisPublikasiPengumuman: [],
  dataComboTipeFile: [],
  dataComboGolonganPegawai: [],
  dataComboJenisKelamin: [],
  dataComboStatusPerkawinan: [],
  dataComboPegawai: [],
  dataComboJenisFile: [],
  dataComboJenisPublikasi: [],
  
  dataComboJenjangPendidikan: [],
  dataComboBulan: [],
  dataComboTahun: [],
};

const comboReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_COMBO_HARIS":
      return { ...state, dataComboHari: action.payload };
    case "DATA_COMBO_UNITS":
      return { ...state, dataComboUnit: action.payload };
    case "DATA_COMBO_ROLES":
      return { ...state, dataComboRole: action.payload };
    case "DATA_COMBO_AGAMAS":
      return { ...state, dataComboAgama: action.payload };
    case "DATA_COMBO_JENIS_KELAMINS":
      return { ...state, dataComboJenisKelamin: action.payload };
    case "DATA_COMBO_JENIS_PEGAWAIS":
      return { ...state, dataComboJenisPegawai: action.payload };
    case "DATA_COMBO_JENIS_PENGAJUANS":
      return { ...state, dataComboJenisPengajuan: action.payload };
    case "DATA_COMBO_JENIS_CUTIS":
      return { ...state, dataComboCuti: action.payload };
    case "DATA_COMBO_JENIS_PENGUMUMANS":
      return { ...state, dataComboJenisPengumuman: action.payload };
    case "DATA_COMBO_JENIS_PUBLIKASI_KEGIATANS":
      return { ...state, dataComboJenisPublikasiKegiatan: action.payload };
    case "DATA_COMBO_JENIS_PUBLIKASI_PENGUMUMANS":
      return { ...state, dataComboJenisPublikasiPengumuman: action.payload };
    case "DATA_COMBO_TIPE_FILES":
      return { ...state, dataComboTipeFile: action.payload };
    case "DATA_COMBO_GOLONGAN_PEGAWAIS":
      return { ...state, dataComboGolonganPegawai: action.payload };
    case "DATA_COMBO_JABATANS":
      return { ...state, dataComboJabatan: action.payload };
    case "DATA_COMBO_JENJANG_PENDIDIKANS":
      return { ...state, dataComboJenjangPendidikan: action.payload };
    case "DATA_COMBO_STATUS_PERKAWINANS":
      return { ...state, dataComboStatusPerkawinan: action.payload };
    case "DATA_COMBO_PEGAWAIS":
      return { ...state, dataComboPegawai: action.payload };
    case "DATA_COMBO_JENIS_FILES":
      return { ...state, dataComboJenisFile: action.payload };
    case "DATA_COMBO_JENIS_PUBLIKASI":
      return { ...state, dataComboJenisPublikasi: action.payload };

    case "DATA_COMBO_BULAN":
      return { ...state, dataComboBulan: action.payload };
    case "DATA_COMBO_TAHUN":
      return { ...state, dataComboTahun: action.payload };
    default:
      return state;
  }
};

export default comboReducer;
