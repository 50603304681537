import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormPegawaiAktivitas = (formType, formValue) => {
  return { type: "FORM_PEGAWAI_AKTIVITAS", formType, formValue };
};

export const GenerateExcelKehadiranAktivitas = (idPegawaiAktivitas) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GenerateExcelKehadiranAktivitas?idPegawaiAktivitas=${idPegawaiAktivitas}`,{responseType:"blob"}).then((res) => {
      loadingBar(false);
      let data = res.data;
      window.open(window.URL.createObjectURL(data))
    });
  };
};

export const setPegawaiAktivitasKehadirans = (idPegawaiAktivitas) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiAktivitasKehadirans?idPegawaiAktivitas=${idPegawaiAktivitas}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_AKTIVITAS_KEHADIRANS", payload: data.data });
      } else {
        if (data.returnMessage !== "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setPegawaiAktivitass = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiAktivitases`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_AKTIVITASS", payload: data.data });
      } else {
        if (data.returnMessage !== "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setPegawaiAktivitas = (idPegawaiAktivitas) => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(
        `${URLSVC}/Webs/GetPegawaiAktivitas?idPegawaiAktivitas=${idPegawaiAktivitas}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.isSuccess) {
          dispatch({ type: "DATA_PEGAWAI_AKTIVITAS", payload: data.data });
        } else {
          if (data.returnMessage != "data tidak ada")
            Swal.fire("Gagal", `${data.returnMessage}`, "error");
        }
      });
  };
};

export const apiSavePegawaiAktivitas = (iData, isEdit, reset) => {
  loadingBar(true);
  return (dispatch) => {
    var Url = "";
    var fd = new FormData();

    if (isEdit) {
      Url = `${URLSVC}/Webs/UpdatePegawaiAktivitas`;
      fd.append("idPegawaiAktivitas", iData.IdPegawaiAktivitas);
    } else {
      if(iData.SaveBy == "1"){
        Url = `${URLSVC}/Webs/SavePegawaiAktivitas`;
        iData.IdPegawais.map((item, index) => {
          fd.append(`idPegawais[${index}]`, item.value);
        });
      } else if(iData.SaveBy == "2"){
        Url = `${URLSVC}/Webs/SavePegawaiAktivitasByJabatan`;
        fd.append('idJabatan', iData.IdJabatan?.value);
      } else if(iData.SaveBy == "3"){
        Url = `${URLSVC}/Webs/SavePegawaiAktivitasByUnit`;
        fd.append('idUnit', iData.IdUnit?.value);
      } else if(iData.SaveBy == "4"){
        Url = `${URLSVC}/Webs/SavePegawaiAktivitas`;
      }
    }

    fd.append("judul", iData.Judul);
    fd.append("deskripsi", iData.Deskripsi);
    fd.append("jadwalMulai", iData.JadwalMulai);
    fd.append("jadwalSelesai", iData.JadwalSelesai);
    fd.append("tanggal", FUNCDateToString(iData.Tanggal));
    

    http.post(Url, fd).then((res) => {
      let data = res.data;
      loadingBar(false);
      if (data.isSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda berhasil menyimpan data aktifitas pegawai`,
          "success"
        );
        dispatch(setPegawaiAktivitass());
        dispatch({ type: "MODAL_PEGAWAI_AKTIVITAS", payload: false });
        reset();
      } else {
        Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiDeletePegawaiAktivitas = (param) => {
  loadingBar(true);
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Kamu akan mengahapus aktifitas pegawai (${param.judul})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, Saya Yakin!",
      cancelButtonText: "Batalkan",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("idPegawaiAktivitas", param.idPegawaiAktivitas);
        http.post(`${URLSVC}/Webs/DeletePegawaiAktivitas`, fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
            dispatch(setPegawaiAktivitass());
            Swal.fire(
              "Success",
              `Anda berhasil menghapus data (${param.judul})`,
              "success"
            );
          } else {
            Swal.fire("Fail", `${data.returnMessage}`, "error");
          }
        });
      }
    });
  };
};
