import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormLaporanAbsensiHarian = (formType, formValue) => {
  return { type: "FORM_LAPORAN_ABSENSI_HARIAN", formType, formValue };
};

export const setDataLaporanAbsensiHarians = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetRankingAbsensiHarians`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_LAPORAN_ABSENSI_HARIANS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const GenerateExcelKehadiranHarian = (minTanggal,maxTanggal) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GenerateExcelKehadiranHarian?minTanggal=${minTanggal}&maxTanggal=${maxTanggal}`,{responseType:"blob"}).then((res) => {
      loadingBar(false);
      let data = res.data;
      window.open(window.URL.createObjectURL(data))
      // if (data.isSuccess) {
      //   dispatch({ type: "DATA_LAPORAN_ABSENSI_REKAP", payload: data.data });
      // } else {
      //   if (data.returnMessage != "data tidak ada")
      //     Swal.fire("Gagal", `${data.returnMessage}`, "error");
      // }
    });
  };
};