import { http } from 'src/config';
import { FUNCDateToString } from 'src/config/function';
import { URLSVC, URL } from 'src/config/helper/urlService';
import Swal from 'sweetalert2';
import { setFormInfoUser } from '.';
import { setImgPreview, loadingBar } from './globalAction';

export const setDataUserInfo = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetInfoUser`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                localStorage.setItem('DATA_USER', JSON.stringify(data.data))
                
                dispatch(setFormInfoUser('Username', data.data.username));
                dispatch(setFormInfoUser('Email', data.data.email));
                dispatch(setFormInfoUser('FirstName', data.data.firstName));
                dispatch(setFormInfoUser('MiddleName', data.data.middleName));
                dispatch(setFormInfoUser('LastName', data.data.lastName));
                dispatch(setFormInfoUser('MobileNumber', data.data.mobileNumber));
                dispatch(setFormInfoUser('PhoneNumber', data.data.phoneNumber));
                dispatch(setFormInfoUser('Address', data.data.address));
                dispatch(setFormInfoUser('IdRole', data.data.roles[0].idRole));

                dispatch(setImgPreview('FILE_IMAGE_PROFILE_PREVIEW', `${data.data.FileImage}?${new Date().getTime()}`));

            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error');
            }
        })
    }
}

export const setDataUserInfoSession = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetInfoUser`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                var dataUser = localStorage.getItem('DATA_USER');
                dataUser = JSON.parse(dataUser);
                dispatch({type: 'DATA_USER_SESSION', payload: dataUser});
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error');
            }
        })
    }
}

export const setDataDashboards = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetDashboard`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.isSuccess) {
                dispatch({type: 'DATA_HOME', payload: data.data});
            } else {
                Swal.fire('Gagal', `${data.returnMessage}`, 'error');
            }
        })
    }
}