import Swal from "sweetalert2";
import {
  URLSVC,
  FUNCArraySelectKode,
  FUNCArraySelectId,
  FUNCArraySelectRole,
  http,
} from "src/config";
import axios from "axios";

export const setDataCombo = (type,Parameter) => {
  return (dispatch) => {
      switch(type){
        case "DATA_COMBO_JENIS_PUBLIKASI":
          http.get(`${URLSVC}/Reffs/GetJenisPublikasis`).then((res) => {
              let data = res.data
              if (data.isSuccess) {
                  let array = FUNCArraySelectId(data.data)
                  dispatch({ type, payload: array })
              } else {
                  Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
              }
          })
          break;
        case "DATA_COMBO_HARIS":
            var arrayData = [
                "Minggu",
                "Senin",
                "Selasa",
                "Rabu",
                "Kamis",
                "Jum'at",
                "Sabtu"
            ];
            var array = [];
            arrayData.map((v, i) => {
                array.push({
                    value: i,
                    label: v,
                });
            });
            dispatch({ type, payload: array });
            break;
        case "DATA_COMBO_UNITS":
          http.get(`${URLSVC}/Reffs/GetUnits`).then((res) => {
              let data = res.data
              if (data.isSuccess) {
                  let array = FUNCArraySelectId(data.data)
                  dispatch({ type, payload: array })
              } else {
                  Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
              }
          })
          break;
        case "DATA_COMBO_ROLES":
            http.get(`${URLSVC}/Reffs/GetRoles`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_AGAMAS":
            http.get(`${URLSVC}/Reffs/GetAgamas`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_KELAMINS":
            http.get(`${URLSVC}/Reffs/GetJenisKelamins`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectKode(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_PEGAWAIS":
            http.get(`${URLSVC}/Reffs/GetJenisPegawais`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_PENGAJUANS":
            http.get(`${URLSVC}/Reffs/GetJenisPengajuans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_CUTIS":
            http.get(`${URLSVC}/Reffs/GetJenisCutis`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    var array = [];
                    (data.data).map((v, i) => {
                        var MaxHari = v.maxHari > 0 ? " - (Maksimal "+v.maxHari+" Hari)" : "";
                        array.push({
                        value: v.idJenisCuti,
                        label: v.nama+""+MaxHari,
                        });
                    });
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_PENGUMUMANS":
            http.get(`${URLSVC}/Reffs/GetJenisPengumumans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_PUBLIKASI_KEGIATANS":
            http.get(`${URLSVC}/Reffs/GetJenisPublikasiKegiatans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_PUBLIKASI_PENGUMUMANS":
            http.get(`${URLSVC}/Reffs/GetJenisPublikasiPengumumans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_TIPE_FILES":
            http.get(`${URLSVC}/Reffs/GetTipeFiles`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_GOLONGAN_PEGAWAIS":
            http.get(`${URLSVC}/Reffs/GetGolonganPegawais`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JABATANS":
            http.get(`${URLSVC}/Reffs/GetJabatans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENJANG_PENDIDIKANS":
            http.get(`${URLSVC}/Reffs/GetJenjangPendidikans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_STATUS_PERKAWINANS":
            http.get(`${URLSVC}/Reffs/GetStatusPerkawinans`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_PEGAWAIS":
            http.get(`${URLSVC}/Webs/GetPegawais`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    var array = [];
                    (data.data).map((v) => {
                        array.push({
                            value: v.idPegawai,
                            label: `${v.namaPegawai} (${v.nip})`,
                        });
                    })
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
        case "DATA_COMBO_JENIS_FILES":
            http.get(`${URLSVC}/Reffs/GetJenisFiles`).then((res) => {
                let data = res.data
                if (data.isSuccess) {
                    let array = FUNCArraySelectId(data.data)
                    dispatch({ type, payload: array })
                } else {
                    Swal.fire('Fail', `${data.ReturnMessage}`, 'error')
                }
            })
            break;
            default:
              break;
      }
  }
}

export const setDataComboUnit = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Reffs/GetUnits`).then((res) => {
      let data = res.data;
      if (data.isSuccess) {
        let array = FUNCArraySelectId(data.data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataComboBulan = (type) => {
  return (dispatch) => {
    var arrayBulan = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    var array = [];
    arrayBulan.map((v, i) => {
      array.push({
        value: i + 1,
        label: v,
      });
    });
    dispatch({ type, payload: array });
  };
};
export const setDataComboTahun = (type) => {
  return (dispatch) => {
    var tahuns = new Date().getFullYear();
    var count = 5;

    var array = [];
    for (let index = 0; index < count; index++) {
      array.push({
        value: tahuns,
        label: tahuns,
      });
      tahuns -= 1;
    }
    dispatch({ type, payload: array });
  };
};
