const initialState = {
    dataHome: [],
  };
  
  const comboReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_HOME":
        return { ...state, dataHome: action.payload };
      default:
        return state;
    }
  };
  
  export default comboReducer;
  