import { FUNCSetFullName, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setImgPreview } from "./globalAction";

export const setFormUser = (formType, formValue) => {
  return { type: "FORM_USER", formType, formValue };
};

export const setDataUsers = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetUsers`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_USERS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataUser = (IdUser) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetUser?IdUser=${IdUser}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_USER", payload: data.data });
        dispatch(setFormUser('IdUser', data.data.idUser))
        dispatch(setFormUser('Username', data.data.username))
        dispatch(setFormUser('Email', data.data.email))
        dispatch(setFormUser('FirstName', data.data.firstName))
        dispatch(setFormUser('MiddleName', data.data.middleName))
        dispatch(setFormUser('LastName', data.data.lastName))
        dispatch(setFormUser('Address', data.data.address))
        dispatch(setFormUser('PhoneNumber', data.data.phoneNumber))
        dispatch(setFormUser('MobileNumber', data.data.mobileNumber))

        dispatch(setFormUser('Role', {
            value:data.data.roles[0].id,
            label:data.data.roles[0].name
        }))

        dispatch({type: 'MODAL_USER', payload: true})
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveUser = (iData,reset) => {
  return (dispatch) => {
      console.log("iData User",iData);
      var Url = "";
      var fd = new FormData();
      if(iData.IdUser != null && iData.IdUser != ""){
        Url = `${URLSVC}/Webs/UpdateUser`;
        fd.append('IdUser', iData.IdUser);
      } else {
        Url = `${URLSVC}/Webs/AddUser`;
      }
      fd.append('IdRole', iData.Role.value);
      fd.append('Email', iData.Email);
      fd.append('FirstName', iData.FirstName);
      fd.append('MiddleName', iData.MiddleName);
      fd.append('LastName', iData.LastName);
      fd.append('Address', iData.Address);
      fd.append('PhoneNumber', iData.PhoneNumber);
      fd.append('MobileNumber', iData.MobileNumber);
      fd.append('FotoProfil', iData.FotoProfil[0]);

      http.post(Url,fd).then((res) => {
          let data = res.data;
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data pengguna`, 'success')
              dispatch(setDataUsers())
              dispatch({ type: 'MODAL_USER', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiAktifasiDeAktifasi = (isAction,param) => {
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan ${isAction == `SetAktif` ? `meng-aktifkan akun` : `menon-aktifkan akun`} atas nama ${FUNCSetFullName(
            param.firstName,
            param.middleName,
            param.lastName
          )}!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('IdPegawai', param.idUser);
              http.post(`${URLSVC}/Webs/${isAction}`,fd).then((res) => {
                  let data = res.data;
                  if (data.isSuccess) {
                    dispatch(setDataUsers())
                    Swal.fire('Success', `Anda berhasil ${isAction == `SetAktif` ? `meng-aktifkan akun` : `menon-aktifkan akun`} ${FUNCSetFullName(
                      param.firstName,
                      param.middleName,
                      param.lastName
                    )}`, 'success')
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}