const initialState = {
    dataPengajuans: [],
    dataPengajuanJadwals: [],
    dataPengajuan: null,
    modalPengajuan: false,
    formPengajuan: {},
    fileImageUserPreview: "",
  };
  
  const pegawaiReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_PENGAJUANS":
        return { ...state, dataPengajuans: action.payload };
      case 'MODAL_PENGAJUAN':
        return { ...state, modalPengajuan: action.payload }
      case "DATA_PENGAJUAN_JADWALS":
        return { ...state, dataPengajuanJadwals: action.payload };
      case "DATA_PENGAJUAN":
        return { ...state, dataPengajuan: action.payload };
      case "FORM_PENGAJUAN":
        return {
          ...state,
          formPengajuan: {
            ...state.formPengajuan,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_PENGAJUAN":
        return { ...state, formPengajuan: initialState.formPengajuan };
      default:
        return state;
    }
  };
  
  export default pegawaiReducer;
  