const initialState = {
    sidebarShow: 'responsive'
  }
  
const sidebarReducer = (state = initialState, action) => {
    if (action.type === "SET") {
        return {
            ...state,
            sidebarShow: action.payload
        }
    }
    return state;
}

export default sidebarReducer;
