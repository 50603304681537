import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormLokasiPresensi = (formType, formValue) => {
  return { type: "FORM_LOKASI_PRESENSI", formType, formValue };
};

export const setLokasiPresensis = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetLokasiPresensis`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_LOKASI_PRESENSIS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setLokasiPresensi = (idLokasiPresensi) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetLokasiPresensi?idLokasiPresensi=${idLokasiPresensi}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_LOKASI_PRESENSI", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSaveLokasiPresensi = (iData,isEdit,reset) => {
  loadingBar(true);
  return (dispatch) => {
      var Url = "";
      var fd = new FormData();

      if(isEdit){
        Url = `${URLSVC}/Webs/UpdateLokasiPresensi`;
        fd.append('idLokasiPresensi', iData.IdLokasiPresensi);
      } else {
        Url = `${URLSVC}/Webs/SaveLokasiPresensi`;
      }

      fd.append('nama', iData.Nama);
      fd.append('longitude', iData.Longitude);
      fd.append('latitude', iData.Latitude);
      fd.append('colorPoint', iData.ColorPoint);
      fd.append('colorRadius', iData.ColorRadius);
    
      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data lokasi presensi`, 'success')
              dispatch(setLokasiPresensis())
              dispatch({ type: 'MODAL_LOKASI_PRESENSI', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeleteLokasiPresensi = (param) => {
  loadingBar(true);
  return (dispatch) => {
      Swal.fire({
          title: 'Apakah anda yakin?',
          text: `Kamu akan mengahapus lokasi presensi (${param.nama})!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Saya Yakin!',
          cancelButtonText: 'Batalkan'
      }).then((result) => {
          if (result.isConfirmed) {
              var fd = new FormData();
              fd.append('idLokasiPresensi',param.idLokasiPresensi);
              http.post(`${URLSVC}/Webs/DeleteLokasiPresensi`,fd).then((res) => {
                  let data = res.data;
                  loadingBar(false);
                  if (data.isSuccess) {
                    dispatch(setLokasiPresensis())
                    Swal.fire('Success', `Anda berhasil menghapus data (${param.nama})`, 'success')
                  } else {
                      Swal.fire('Fail', `${data.returnMessage}`, 'error')
                  }
              })
          }
      })
  }
}