const initialState = {
  isLoading: false,
  gbDataUser: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: action.payload };
    case "GB_DATA_USER":
      return { ...state, gbDataUser: action.payload };
    default:
      return state;
  }
};

export default globalReducer;
