import { FUNCDateToString, FUNCMonthToString, FUNCYearToString, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormPegawaiGaji = (formType, formValue) => {
  return { type: "FORM_PEGAWAI_GAJI", formType, formValue };
};

export const setDataPegawaiGajis = () => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiGajis`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_GAJIS", payload: data.data });
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataPegawaiGajisByUnit = (IdUnit="") => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiGajisByUnit?IdUnit=${IdUnit}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_GAJIS", payload: data.data });
      } else {
        dispatch({ type: "DATA_PEGAWAI_GAJIS", payload: data.data });
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setDataPegawaiGajisByPegawai = (param) => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetPegawaiGajisByPegawai?idPegawai=${param.idPegawai}&bulan=${param.bulan}&tahun=${param.tahun}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_GAJI", payload: data.data[0] });
      } else {
        dispatch({ type: "DATA_PEGAWAI_GAJI", payload: data.data });
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setGenerateTemplateGajiPegawai = () => {
  loadingBar(true);
  return () => {
    http.get(`${URLSVC}/Webs/GenerateTemplateGajiPegawai`,{responseType:"blob"}).then((res) => {
      loadingBar(false);
      let data = res.data;
      window.open(window.URL.createObjectURL(data));
    });
  };
};

export const setGenerateSlipGajiPegawai = (param) => {
  loadingBar(true);
  return () => {
    http.get(`${URLSVC}/Webs/GenerateSlipGajiPegawai?idPegawai=${param.idPegawai}&bulan=${param.bulan}&tahun=${param.tahun}`,{responseType:"json"}).then((res) => {
      loadingBar(false);
      let result = res.data;
      if (result.isSuccess) {
        window.open(result.data,"_blank");
      } else {
        Swal.fire("Gagal", `${result.returnMessage}`, "error");
      }
    });
  };
};

export const setSaveGajiMassal = (param) => {
  loadingBar(true);
  return (dispatch) => {
    var fd = new FormData();
    fd.append('bulan', FUNCMonthToString(param.Bulan));
    fd.append('tahun', FUNCYearToString(param.Tahun));
    fd.append('formFile', param.FormFile[0]);

    http.post(`${URLSVC}/Webs/SaveGajiMassal`,fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_GAJI", payload: data.data });
      } else {
        dispatch({ type: "DATA_PEGAWAI_GAJI", payload: data.data });
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const apiSavePegawaiGaji = (iData,isEdit,reset) => {
  loadingBar(true);
  return (dispatch) => {
      console.log("iData PEGAWAI_GAJI",iData);
      var Url = "";
      var fd = new FormData();

      if(isEdit){
        Url = `${URLSVC}/Webs/UpdateGaji`;
      } else {
        Url = `${URLSVC}/Webs/SaveGaji`;
      }
      
      fd.append('idPegawai', iData.IdPegawai.value);
      fd.append('bulan', FUNCMonthToString(iData.Bulan));
      fd.append('tahun', FUNCYearToString(iData.Tahun));

      fd.append('namaBank', iData.NamaBank);
      fd.append('noRekening', iData.NoRekening);
      fd.append('hariMasuk', iData.HariMasuk);
      fd.append('gajiPokok', iData.GajiPokok);
      fd.append('tunjanganTransport', iData.TunjanganTransport);
      fd.append('tunjanganFungsional', iData.TunjanganFungsional);
      fd.append('tunjanganKeluarga', iData.TunjanganKeluarga);
      fd.append('tunjanganPembinaan', iData.TunjanganPembinaan);
      fd.append('tunjanganMakanan', iData.TunjanganMakanan);
      fd.append('tambahanJamKerja', iData.TambahanJamKerja);
      fd.append('tunjanganPulsaLeader', iData.TunjanganPulsaLeader);
      fd.append('tunjanganKinerja', iData.TunjanganKinerja);
      fd.append('bpjsKes', iData.BpjsKes);
      fd.append('bpjsTk', iData.BpjsTk);
      fd.append('zis', iData.Zis);
      fd.append('pajak', iData.Pajak);
      fd.append('potKasbon', iData.PotKasbon);
      fd.append('potSpp', iData.PotSpp);
      fd.append('potCicil', iData.PotCicil);
      fd.append('keterangan', iData.Keterangan);

      http.post(Url,fd).then((res) => {
          let data = res.data;
          loadingBar(false);
          if (data.isSuccess) {
              Swal.fire('Berhasil', `Anda berhasil menyimpan data gaji pegawai`, 'success')
              dispatch(setDataPegawaiGajisByUnit(iData.idUnit))
              dispatch({ type: 'MODAL_PEGAWAI_GAJI', payload: false })
              reset();
          } else {
              Swal.fire('Gagal', `${data.returnMessage}`, 'error')
          }
      })
  }
}

export const apiDeletePegawaiGaji = (iData) => {
  loadingBar(true);
  return (dispatch) => {
    Swal.fire({
        title: 'Apakah anda yakin?',
        text: `Kamu akan mengahapus gaji ${iData.namaPegawai} ${iData.bulanTahun}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Saya Yakin!',
        cancelButtonText: 'Batalkan'
    }).then((result) => {
        if (result.isConfirmed) {
          var Url = "";
          var fd = new FormData();
    
          Url = `${URLSVC}/Webs/DeleteGaji`;
          fd.append('IdPegawai', iData.idPegawai);
          fd.append('Bulan', iData.bulan);
          fd.append('Tahun', iData.tahun);
    
          http.post(Url,fd).then((res) => {
              let data = res.data;
              loadingBar(false);
              if (data.isSuccess) {
                  Swal.fire('Berhasil', `Anda berhasil menghapus gaji pegawai`, 'success')
                  dispatch(setDataPegawaiGajisByUnit(iData.unit.id))
              } else {
                  Swal.fire('Gagal', `${data.returnMessage}`, 'error')
              }
          })
        }
    })
  }
}