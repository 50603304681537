import React from "react";
const ProgressBar = (
  <div
    id="loading"
    className="bg-primary"
    style={{
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
    }}
  >
    <div
      style={{
        height: "100%",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <center>
        <img
          className="pb-3"
          src={"assets/images/loading.gif"}
          width="100px"
          alt="Loading..."
        />
      </center>
    </div>
  </div>
);
export default ProgressBar;
