const initialState = {
    dataLaporanAbsensiRekaps: [],
    dataLaporanAbsensiRekap: null,
    modalLaporanAbsensiRekap: false,
    formLaporanAbsensiRekap: {},
    fileImageUserPreview: "",
    modalLaporanJadwal: false,
  };
  
  const laporanAbsensiRekapReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DATA_LAPORAN_ABSENSI_REKAPS":
        return { ...state, dataLaporanAbsensiRekaps: action.payload };
      case 'MODAL_LAPORAN_ABSENSI_REKAP':
        return { ...state, modalLaporanAbsensiRekap: action.payload }
      case "DATA_LAPORAN_ABSENSI_REKAP":
        return { ...state, dataLaporanAbsensiRekap: action.payload };
      case "FORM_LAPORAN_ABSENSI_REKAP":
        return {
          ...state,
          formLaporanAbsensiRekap: {
            ...state.formLaporanAbsensiRekap,
            [action.formType]: action.formValue,
          },
        };
      case "RESET_FORM_LAPORAN_ABSENSI_REKAP":
        return { ...state, formLaporanAbsensiRekap: initialState.formLaporanAbsensiRekap };
      case "MODAL_LAPORAN_JADWAL":
        return { ...state, modalLaporanJadwal: action.payload };
      default:
        return state;
    }
  };
  
  export default laporanAbsensiRekapReducer;
  