import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { FUNCDateToString, FUNCIndoDate, http } from "src/config";
import { URLSVC, URL } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "../globalAction";

export const setFormPegawaiCuti = (formType, formValue) => {
  return { type: "FORM_HARI_LIBUR", formType, formValue };
};

export const setPegawaiCutis = (tahun="") => {
  loadingBar(true);
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKuotaCutis?Tahun=${tahun}`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch({ type: "DATA_PEGAWAI_CUTIS", payload: data.data });
      } else {
        dispatch({ type: "DATA_PEGAWAI_CUTIS", payload: [] });
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};

export const setKuotaCuti = (tahun="") => {
  loadingBar(true);
  return (dispatch) => {
    var fd = new FormData();
    fd.append('Tahun', tahun);

    http.post(`${URLSVC}/Webs/SetKuotaCuti`,fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.isSuccess) {
        dispatch(setPegawaiCutis(tahun))
        Swal.fire('Berhasil', `Anda berhasil menyimpan kuota cuti`, 'success')
      } else {
        if (data.returnMessage != "data tidak ada")
          Swal.fire("Gagal", `${data.returnMessage}`, "error");
      }
    });
  };
};